import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";

const TermAndCondition = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Box sx={{ display: "flex", justifyContent: "center", bgcolor: "#FAFAFA" }}>
      <Box
        sx={{
          maxWidth: "1300px",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 4,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          General
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          <b>
            {" "}
            “<a href="https://drivershaab.in/">www.drivershaab.in</a>”
          </b>{" "}
          (hereinafter, the “Website/App”) is owned and operated by SATADRU
          TECHNOLOGIES PRIVATE LIMITED, (hereinafter referred to as
          <b>“Private Limited Company”</b>), having its registered office at
          42/132/A, New Ballygunge Road,Kolkata 700039, India
          <br />
          This document is an electronic record published in accordance with the
          provisions of the Information Technology (Intermediaries guidelines)
          Rules, 2011 that require publishing the Rules and Regulations, Privacy
          Policy and Terms of Service for access or usage of the Website/App and
          being generated by a computer system, it does not require any physical
          or digital signatures.
          <br />
          For the purpose of these Terms of Use, along with any amendments to
          the same and wherever the context so requires
          <br />.<b>“You”, “Your”, “Yourself” or “User”</b> shall mean and refer
          to the person visiting, accessing, browsing through and/or using the
          Website/App at any point in time and shall include the
          “Clients/Customers”
          <br />. Further it shall also mean and refer to persons who are
          <b>“Vendors/Drivers” and “Service Provider” </b>for the Website/App.
          However, where a Client/Customer or Vendors/Drivers or Service
          Provider is mentioned specifically it shall only mean and refer to
          that class of User.
          <br />
          .The term “We”, “Us”, “Our”,DriverShaab”,Firm” shall mean and refer to
          the Website/App and/or the SATADRU TECHNOLOGIES PRIVATE LIMITED,
          depending on the context.
          <br />
          .This Agreement shall cover your usage of the Website/App as a User,
          which shall include Customers, Vendors/Drivers/Service Providers.
          Further the Vendors/Drivers/Service Providers are also bound by the
          Vendor Terms signed with the Private Limited Company, besides this
          Agreement.
          <br />
          .The headings of each section in this Agreement are only for the
          purpose of organizing the various provisions under this Agreement in
          an orderly manner. These headings shall not be used by either party to
          interpret the provisions contained under them in any manner. Further,
          the headings have no legal or contractual value.
          <br />
          .We hold the sole right to modify the Terms of Service without prior
          permission from You or providing notice to You. The relationship
          creates on You a duty to periodically check the Terms of Service and
          stay updated on its requirements. If You continue to use the
          Website/App or avail any of its services without registration
          following such change, this is deemed as consent by You to the so
          amended policies. Your continued use of the Website/App is conditioned
          upon your compliance with the Terms of Service, including but not
          limited to compliance with the Terms of Service even after
          alterations, if any.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          About Us
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          Drivershaab (SATADRU TECHNOLOGIES PRIVATE LIMITED) is an on demand
          professional car driver service. Customer shall just hire a driver and
          reach their destination smoothly. DriverShaab is a platform where you
          can end your tiring driving by booking a driver from DriverShaab
          mobile application at any time. Our trained drivers shall provide
          quality services to the customers. The services on the App shall only
          be available for personal car owners. The Services shall be available
          through a user- friendly App wherein our frequent travellers, personal
          car owners and corporates can book drivers. We provide our services
          anywhere any time within Kolkata in 45 minutes from the time of
          booking the services. For package and outstation services we will
          provide the driver services within 3 hours from the time of booking
          the services.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          Services Overview
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          Customers shall visit the Website/App and select their location and
          request for a driver. The Customer shall provide details of the
          destination and the time of pick up and the Driver shall be provided
          with the driver’s status update. <br /> Customers may track their
          driver using the App. Tracking the driver’s current location has
          become easier to book a driver as per the customer’s needs. The Driver
          shall arrive at the destination at the requested time. The driver
          shall take proper care to keep the customer’s car clean and the car
          will be returned home safely. <br /> We provide drivers to customers
          in the luxury of their own car. Whenever a customer decides to end the
          drive, we shall mail the customer the drive summary and the receipt.
          Further the customers may verify the driver’s license but can’t take a
          Xerox of such license or other government documents.
          <br />
          The services shall be provided in Kolkata, Mumbai,
          Delhi-NCR,Bhubaneswar ,Lukhnow, Bangalore , Hyedrabad, Guwahati,
          Siliguri, Pune in the preliminary stages with a few exceptions of zip
          code inside Kolkata, Mumbai, Delhi-NCR, Bhubaneswar, Lukhnow,
          Bangalore , Hyedrabad, Guwahati, Siliguri, Pune. GST shall be applied
          on each and every trip which shall be charged from the customer.
          Drivershaab may cancel any trip without any prior notice to customer.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          Registration
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          To use the services provided on the Website/App, you shall register
          yourself on the Website/App. To create an account, you need to provide
          your email id, mobile number and choose a username and password. You
          must keep your account and registration details current and correct
          for communications related to your bookings from the Website/App.
          Drivershaab shall send message to you to check the authenticity of the
          mobile user during registration.
          <br />
          Information collected about you is subject to the Privacy Policy of
          the Firm, which may be read as part and parcel of these Terms of Use.
          Drivershaab /SATADRU TECHNOLOGIES PRIVATE LIMITED shall record calls
          of call centre and Driver, Driver and customer, and call center and
          customer.
          <br />
          You are solely responsible for protecting the confidentiality of your
          username and password and any activity under the account will be
          deemed to have been done by you. In the event you provide us with
          false/inaccurate details or the Firm has a reasonable ground to
          believe that false and inaccurate information has been furnished, we
          hold the right to permanently suspend your account.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          Eligibility
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          Services on the Site would be available only in
          <b>
            {" "}
            Kolkata, Mumbai, Delhi-NCR,Bhubaneswar, Lukhnow, Bangalore ,
            Hyedrabad, Guwahati, Siliguri,Pune, India.{" "}
          </b>
          Persons who are “incompetent to contract” within the meaning of the
          Indian Contract Act, 1872 including minors, un-discharged insolvents
          etc. are not eligible to use the Site.
          <br />
          However, if you are a minor, i.e. under the age of 18 years and over
          the age of 13 years, you may use the Site under the supervision of a
          parent or legal guardian who agrees to be bound by these Terms of Use.
          We reserve the right to terminate or refuse your registration, or
          refuse to permit access to the Site, if it is discovered or brought to
          its notice that you are a minor.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          Security
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          Transactions on the Website/App are secure and protected. Any
          information entered by the User when transacting on the Website/App is
          encrypted to protect the User against unintentional disclosure to
          third parties. The User’s credit and debit card information is not
          received, stored by or retained by the Firm / Website/App in any
          manner. This information is supplied by the User directly to the
          relevant payment gateway which is authorized to handle the information
          provided, and is compliant with the regulations and requirements of
          various banks and institutions and payment franchisees that it is
          associated with.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          License and Access
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          The Firm grants you no sub-license, whether limited or not, to access
          and make personal use of the Site, and not to download (other than
          page caching) or modify it or any portion of it, except with express
          written consent of the Firm. You are not permitted any resale or
          commercial use of the Site or its contents; any collection and use of
          any product listings, descriptions, or prices; any derivative use of
          the Site or its contents; any downloading or copying of information
          for the benefit of another merchant; or any use of data mining,
          robots, or similar data gathering and extraction tools. Any portion of
          the Site may not be reproduced, duplicated, copied, sold, resold,
          visited, or otherwise exploited for any commercial purpose without
          express written consent of the Firm. You may not frame or utilize
          framing techniques to enclose any trademark, logo, or other
          proprietary information (including images, text, page layout, or form)
          of the Website/App or of the Firm and/or its affiliates without the
          express written consent of the Firm. You may not use any meta tags or
          any other “hidden text” utilizing the Firm’s name or trademarks
          without the express written consent of the Firm. You shall not attempt
          to gain unauthorized access to any portion or feature of the Site, or
          any other systems or networks connected to the Site or to any server,
          computer, network, or to any of the services offered on or through the
          Site, by hacking, ‘pass-word mining’ or any other illegitimate means.
          <br />
          You hereby agree and undertake not to host, display, upload, modify,
          publish, transmit, update or share any information which:
          <br />
          .belongs to another person and to which you do not have any right;
          <br />
          .is grossly harmful, harassing, blasphemous, defamatory, obscene,
          pornographic, pedophilic, libelous, invasive of another’s privacy,
          hateful, or racially, ethnically objectionable, disparaging, relating
          or encouraging money laundering or gambling, or otherwise unlawful in
          any manner whatever;
          <br />
          .harms minors in any way;
          <br />
          .infringes any patent, trademark, copyright or other
          proprietary/intellectual property rights;
          <br />
          .violates any law for the time being in force;
          <br />
          .deceives or misleads the addressee about the origin of such messages
          communicates any information which is grossly offensive or menacing in
          nature;
          <br />
          .impersonates another person;
          <br />
          .contains software viruses or any other computer code, files or
          programs designed to interrupt, destroy or limit the functionality of
          any computer resource;
          <br />
          .threatens the unity, integrity, defense, security or sovereignty of
          India, friendly relations with foreign states, or public order or
          causes incitement to the commission of any cognizable offence or
          prevents investigation of any offence or is insulting any other
          nation;
          <br />
          .or is misleading or known to be false in any way.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          Communications
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          By using this Website/App, it is deemed that you have consented to
          receiving calls, autodialed and/or pre-recorded message calls,
          e-mails, from Us at any time with the use of the telephone number and
          e-mail address that has been provided by you for the use of this
          Website/App which are subject to the Privacy Policy. The user agrees
          to receive promotional communication and newsletters from the Firm and
          its partners.
          <br />
          This includes contacting you through information received through
          other parties. The use of this Website/App is also your consent to
          receive SMS from Us at any time we deem fit. This consent to be
          contacted is for purposes that include and are not limited to
          clarification calls and marketing and promotional calls. The user can
          opt out from such communication and/or newsletters either by
          unsubscribing on the Website/App itself, or by contacting the customer
          services team and placing a request for unsubscribing by sending an
          email to info@drivershaab.com.
          <br />
          You may also be contacted by Service Providers with whom we have
          entered into a contract in furtherance of our rights, duties and
          obligations under this document and all other policies followed by Us.
          Such contact will be made only in pursuance of such objectives, and no
          other calls will be made.
          <br />
          The sharing of the information provided by you will be governed by the
          Privacy Policy and We may or may not sell your information to third
          parties.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          Payments and Fees
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          The Services available on the Website/App require a payment of fees.
          The Firm reserves the right to change its price list, plans and to
          institute new charges at any time, and the same may or may not be
          notified to the User.
          <br />
          There shall be various plans available to the Vendors/Drivers to
          choose from, the pricing and package for which shall be covered by a
          separate Vendors/Drivers Agreement signed between the Vendors/Drivers
          and the Firm.
          <br />
          One or many of the following payment options are available on the
          Website/App:
          <br />
          .Domestic and international credit cards issued by banks and financial
          institutions that are part of the .Visa, Master Card & Amex Card
          networks Visa & Master Card Debit cards;
          <br />
          .Bank wire Other payment gateways such as Digital wallets, UPI,
          PayPal, Moneybooker, PayuMoney and any other payment mechanism that
          comes into usage in the future. Net banking/Direct Debit payments from
          select banks in India. A list of available options will be made
          available at the time of ‘checkout’.
          <br />
          As prescribed by the financial institutions issuing the credit or
          debit cards affiliated with Visa / Master Card / Amex, the User will
          be required to submit his/her 16-digit card number, card expiry date
          and 3-digit CVV number (usually on the reverse of the card) while
          making an online transaction. The User must also have enrolled his/her
          card with VBV (Verified by Visa), MSC (MasterCard Secure Code) or any
          other applicable provider in order to complete the transaction. The
          User is hereby expressly made aware that his/her card statements will
          reflect that a payment has been made in favour of the Firm.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          User Obligations
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          .You are a restricted user of this Website/App.
          <br />
          .You are bound not to cut, copy, distribute, modify, recreate, reverse
          engineer, distribute, disseminate, post, publish or
          <br />
          .create derivative works from, transfer, or sell any information or
          software obtained from the Website/App. With our prior permission
          limited use may be allowed. For the removal of doubt, it is clarified
          that unlimited or wholesale reproduction, copying of the content for
          commercial or non-commercial purposes and unwarranted modification of
          data and information within the content of the Website/App is not
          permitted.
          <br />
          .You agree not to access (or attempt to access) the Website/App and/or
          the materials or Services by any means other than through the
          interface that is provided by the Website/App. The use of deep-link,
          robot, spider or other automatic device, program, algorithm or
          methodology, or any similar or equivalent manual process, to access,
          acquire, copy or monitor any portion of the Website/App or Content, or
          in any way reproduce or circumvent the navigational structure or
          presentation of the Website/App, materials or any Content, to obtain
          or attempt to obtain any materials, documents or information through
          any means not specifically made available through the Website/App. You
          acknowledge and agree that by accessing or using the Website/App or
          Services, You may be exposed to content from other users that You may
          consider offensive, indecent or otherwise objectionable. We disclaim
          all liabilities arising in relation to such offensive content on the
          Website/App. Further, You may report such offensive content.In places
          where this Website/App allows you to post or upload data/information,
          You undertake to ensure that such material is not offensive and in
          accordance with applicable laws. Further,
          <br />
          .You undertake not to: Abuse, harass, threaten, defame, disillusion,
          erode, abrogate, demean or otherwise violate the legal rights of
          others;
          <br />
          .Engage in any activity that interferes with or disrupts access to the
          Website/App or the Services (or the servers and networks which are
          connected to the Website/App);
          <br />
          .Impersonate any person or entity,
          <br />
          or falsely state or otherwise misrepresent Your affiliation with a
          person or entity;
          <br />
          .Publish, post, disseminate, any information which is grossly harmful,
          harassing, blasphemous, defamatory, obscene, pornographic, pedophilic,
          libelous, invasive of another’s privacy, hateful, or racially,
          ethnically objectionable, disparaging, relating or encouraging money
          laundering or gambling, or otherwise unlawful in any manner whatever;
          or unlawfully threatening or unlawfully harassing including but not
          limited to “indecent representation of women” within the meaning of
          the Indecent Representation of Women (Prohibition) Act, 1986;
          <br />
          .Post any file that infringes the copyright, patent or trademark of
          other legal entities;
          <br />
          Upload or distribute files that contain viruses, corrupted files, or
          any other similar software or programs that may damage the operation
          of the Website/App or another’s computer;
          <br />
          .Download any file posted by another user that you know, or reasonably
          should know, cannot be legally distributed in such manner;
          <br />
          .Probe, scan or test the vulnerability of the Website/App or any
          network connected to the Website/App, nor breach the security or
          authentication measures on the Website/App or any network connected to
          the Website/App. You may not reverse look-up, trace or seek to trace
          any information on any other user, of or visitor to, the Website/App,
          or any other customer of the Website/App, including any Website/App
          Account not owned by You, to its source, or exploit the Website/App or
          Service or information made available or offered by or through the
          Website/App, in any way whether or not the purpose is to reveal any
          information, including but not limited to personal identification
          information, other than Your own information, as provided for by the
          Website/App;
          <br />
          Disrupt or interfere with the security of, or otherwise cause harm to,
          the Website/App, system resources, accounts, passwords, servers or
          networks connected to or accessible through the Website/App or any
          affiliated or linked sites;
          <br />
          Collect or store data about other users in connection with the
          prohibited conduct and activities set forth in this Section;
          <br />
          Use the Website/App or any material or Content for any purpose that is
          un-lawful or prohibited by these Terms of Service, or to solicit the
          performance of any illegal activity or other activity which infringes
          the rights of this Website/App or other third parties;
          <br />
          .Violate any code of conduct or other guidelines, which may be
          applicable for or to any particular Service;
          <br />
          .Violate any applicable laws or regulations for the time being in
          force within or outside India;
          <br />
          .Violate the Terms of Service including but not limited to any
          applicable Additional Terms of the Website/App contained herein or
          elsewhere;
          <br />
          .Violate any code of conduct or other guidelines, which may be
          applicable for or to any particular Service;
          <br />
          Threaten the unity, integrity, defence, security or sovereignty of
          India, friendly relations with foreign states, or public order or
          cause incitement to the commission of any cognizable offence or
          prevent investigation of any offence or is insulting any other nation;
          <br />
          Publish, post, disseminate information that is false, inaccurate or
          misleading;
          <br />
          .violate any applicable laws or regulations for the time being in
          force in or outside India;
          <br />
          .Directly or indirectly, offer, attempt to offer, trade or attempt to
          trade in any item, the dealing of which is prohibited or restricted in
          any manner under the provisions of any applicable law, rule,
          regulation or guideline for the time being in force;
          <br />
          .Create liability for Us or cause Us to lose (in whole or in part) the
          services of Our internet service provider (“ISPs”) or other suppliers.
          We have no obligation to monitor the materials posted on the
          Website/App. We shall have the right to remove or edit any content
          that in its sole discretion violates, or is alleged to violate, any
          applicable law or either the spirit or letter of these Terms of
          Service. Not with standing this right, YOU REMAIN SOLELY RESPONSIBLE
          FOR THE CONTENT OF THE MATERIALS YOU POST ON THE WEBSITE/APP AND IN
          YOUR PRIVATE MESSAGES. In no event shall We assume or have any
          responsibility or liability for any Content posted or for any claims,
          damages or losses resulting from the use of Content and/or appearance
          of Content on the Website/App. You hereby represent and warrant that
          You have all necessary rights in and to all Content which You provide
          and all information it contains and that such Content shall not
          infringe any proprietary or other rights of third parties or contain
          any libellous, tortuous, or otherwise unlawful information.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          Copyright and Trademark
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          .The Firm, its suppliers and licensors expressly reserve all
          intellectual property rights in all text, programs, products,
          processes, technology, images, content and other materials which
          appear on the Site. Access to or use of the Site does not confer and
          should not be considered as conferring upon anyone any license to the
          Firm or any third party’s intellectual property rights. All rights,
          including copy-right, in and to the Site are owned by or licensed to
          the Firm. Any use of the Site or its contents, including copying or
          storing it or them in whole or part is prohibited without the
          permission of the Firm.
          <br />
          .You may not modify, distribute or re-post anything on the Site for
          any purpose. The names and logos and all related product and service
          names, design marks and slogans are the trademarks/service marks of
          the Firm, its affiliates, its partners or its suppliers/service
          providers. All other marks are the property of their respective
          owners. No trademark or service mark license is granted in connection
          with the materials contained on the Site. Access to or use of the Site
          does not authorize anyone to use any name, logo or mark in any manner.
          References on the Site to any names, marks, products or services of
          third parties or hypertext links to third party sites or information
          are provided solely as a convenience to you and do not in any way
          constitute or imply the Firm’s endorsement, sponsorship or
          recommendation of the third party, the information, its product or
          services.
          <br />
          .The Firm is not responsible for the content of any third party sites
          and does not make any representations regarding the content or
          accuracy of material on such sites. If you decide to access a link of
          any third party Website/Apps, you do so entirely at your own risk and
          expense.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          Disclaimer of Warranties and Liabilities
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          the website/app, services and other materials are provided by this
          website/app is on an “as is” basis without warranty of any kind,
          express, implied, statutory or otherwise, including the implied
          warranties of title, non-infringement, merchantability or fitness for
          a particular purpose. without limiting the foregoing, we make no
          warranty that
          <br />
          .your requirements will be met or that services provided will be
          uninterrupted, timely, secure or error-free;
          <br />
          .materials, information and results obtained will be effective,
          accurate or reliable;
          <br />
          .any errors or defects in the website/app, services or other materials
          will be corrected.to the maximum extent permitted by applicable law,
          we will have no liability related to user content arising under
          intellectual property rights, libel, privacy, publicity, obscenity or
          other laws. we also disclaim all liability with respect to the misuse,
          loss, modification or unavailability of any user content.
          <br />
          .the user understands and agrees that any material or data downloaded
          or otherwise obtained through the website/app is done entirely at
          his/her own discretion and risk and he/she will be solely responsible
          for any damage to his/her computer systems or loss of data that
          results from the download of such material or data. we are not
          responsible for any typographical error leading to an invalid coupon.
          we accept no liability for any errors or omissions, with respect to
          any information provided to you whether on behalf of it-self or third
          parties.
          <br />
          .we shall not be responsible for the relevance and validity of
          comments made by third parties like vendors/drivers, public on the
          platform. the customer shall be wholly responsible for the accuracy of
          email, phone number, and personal information. further in awarding
          points that are redeemable for cash, to users we are not responsible
          if the event expires or changes or is removed altogether.
          <br />
          .we shall not be liable for any third party product or services. the
          advertisement available on e-mail or website/app with respect to the
          third party website/app or the products and services are for
          information purpose only.
          <br />
          .Drivershaab shall not provide any car for customers and is only a
          digital bridge that connects the personal car owner to the
          professional drivers. we shall not be responsible for any accident,
          loss or injury caused to the customer or driver. we may cancel any
          trip without any prior notice. we may cancel any driver’s registration
          without giving any feed-back. we may change the commission rate and
          pricing structure at our sole discretion without giving any prior
          notice and both the customers and drivers shall adhere and agree to
          the same or discontinue usage of our services. further drivers shall
          register with the firm by personally visiting our office and
          registering.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          Indemnification and Limitation of Liability
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          you agree to indemnify, defend and hold harmless this website/app/firm
          including but not limited to its affiliate vendors/drivers, agents and
          employees from and against any and all losses, liabilities, claims,
          damages, demands, costs and expenses (including legal fees and
          disbursements in connection therewith and interest chargeable thereon)
          asserted against or incurred by us that arise out of, result from, or
          may be payable by virtue of, any breach or non-performance of any
          representation, warranty, covenant or agreement made or obligation to
          be performed by you pursuant to these terms of service. further, you
          agree to hold us harmless against any claims made by any third party
          due to, or arising out of, or in connection with, your use of the
          website/app, any claim that your material caused damage to a third
          party, your violation of the terms of service, or your violation of
          any rights of another, including any intellectual property rights.
          <br />
          in no event shall we, our officers, directors, employees, partners,
          suppliers or experts be liable to you, the vendors/drivers or any
          third party for any special, incidental, indirect, consequential or
          punitive damages whatsoever, including those resulting from loss of
          use, data or profits, whether or not foreseeable or whether or not we
          have been advised of the possibility of such damages, or based on any
          theory of liability, including breach of contract or warranty,
          negligence or other tortious action, or any other claim arising out of
          or in connection with your use of or access to the website/app,
          services or materials.
          <br />
          the limitations and exclusions in this section apply to the maximum
          extent permitted by applicable law.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          Termination
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          This User Agreement is effective unless and until terminated by either
          you or the Firm. You may terminate this User Agreement at any time,
          provided that you dis-continue any further use of the Website/App. The
          Firm may terminate this User Agreement at any time and may do so
          immediately without notice, and accordingly deny you access to the
          Site.
          <br />
          Such termination will be without any liability to the Firm. The Firm’s
          right to any Comments and to be indemnified pursuant to the terms
          hereof, shall survive any termination of this User Agreement. Any such
          termination of the User Agreement shall not cancel your obligation to
          pay for product(s)/service(s) already ordered from the Site or affect
          any liability that may have arisen under the User Agreement prior to
          the date of termination.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          Disputes and Jurisdiction
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          All disputes involving but not limited to rights conferred,
          compensation, refunds, and other claims will be resolved through a
          two-step Alternate Dispute Resolution mechanism.
          <br />
          <b>Stage 1:Mediation. </b>In case of a dispute, the matter will first
          be attempted to be resolved by a sole mediator who is a neutral third
          party and will be selected at the mutual acceptance of a proposed
          mediator by both parties. Both parties may raise a name for the sole
          mediator and in the case both parties accept the proposed name, the
          said person shall be appointed as sole mediator. In case the parties
          are not able to reach a consensus within two proposed mediators, the
          Firm reserves the right to decide who the final mediator is. The
          decision of the mediator is not binding on both parties.
          <br />
          <b>Stage 2:Arbitration.</b> In case that mediation does not yield a
          result suitable or preferred by any one of the parties, arbitration
          may follow, the award of which is binding on both parties. The
          Arbitration Board shall comprise three members – one appointed by each
          party and the third member to be nominated by the two appointed
          members by mutual consent. Arbitration shall be held at Kolkata, West
          Bengal, India. The proceedings of arbitration shall be in the Bengali
          language. The arbitrator’s award shall be final and binding on the
          Parties. If the dispute cannot be resolved by this two-step Alternate
          Dispute Resolution mechanism, it shall be referred to the courts at
          Kolkata, West Bengal, India.
          <br />
          If the dispute cannot be resolved by this two-step Alternate Dispute
          Resolution mechanism, it shall be referred to the courts at Kolkata,
          West Bengal, India.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          Privacy
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          We encourage you to read the Privacy Policy and to use the information
          it contains to make informed decisions regarding Your personal
          information. Please also note that certain information, statements,
          data and content (such as but not limited to photographs) which You
          provide on the Website/App are likely to reveal Your gender, ethnic
          origin, nationality, age, and/or other personal information about You.
          You acknowledge and agree that your submission of such information is
          voluntary on Your part. Further, You acknowledge, consent and agree
          that we may access, preserve, and disclose information You provide to
          Us at any stage during Your use of the Website/App. Disclosures of
          information to Third Parties are further addressed in Our Privacy
          Policy.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          Miscellaneous Provisions
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          Entire Agreement: The terms and conditions set forth in this Agreement
          and any additional or different terms expressly agreed by Client and
          Service Vendors/Drivers shall constitute the entire agreement and
          understanding of Client and Service Vendors/Drivers with respect to
          each Service Contract and shall cancel and supersede any other prior
          or contemporaneous discussions ,agreements, representations,
          warranties, and/or other communications between them. Notwithstanding
          the foregoing, the Client and Service Vendors/Drivers shall always
          remain subject to the terms of Our user Agreement.
          <br />
          Waiver: The failure of either party at any time to require performance
          of any provision of this Agreement in no manner shall affect such
          party’s right at a later time to enforce the same. No waiver by either
          party of any breach of this Agreement, whether by conduct or
          otherwise, in any one or more instances, shall be deemed to be, or
          construed as, a further or continuing waiver of any other such breach,
          or a waiver of any other breach of this Agreement.
          <br />
          Severability: If any provision of this Agreement shall to any extent
          be held invalid, illegal or unenforceable, the validity, legality and
          enforceability of the remaining provisions of this Agreement shall in
          no way be affected or impaired thereby and each such provision of this
          Agreement shall be valid and enforceable to the fullest extent
          permitted by law. In such a case, this Agreement shall be reformed to
          the minimum extent necessary to correct any invalidity, illegality or
          unenforceability, while preserving to the maximum extent the rights
          and commercial expectations of the parties hereto, as expressed
          herein.
        </Typography>{" "}
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "26px",
            color: "#FB561E",
          }}
        >
          Contact Us
        </Typography>
        <Typography
          sx={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
            mb: 3,
          }}
        >
          If you have any questions about this Agreement, the practices of, or
          your experience with the Service, you can e-mail us at
          info@drivershaab.com.
        </Typography>
      </Box>
    </Box>
  );
};

export default TermAndCondition;
