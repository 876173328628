import React from 'react'
import Schedule_Demo from "../component/Schudule_Demo/Schedule_Demo"
import AppSection from "../component/AppSection";
import Footer from "../component/Footer";


const ScheduleDemo = () => {
  return (
    <div style={{marginTop:'50px'}}>
      <Schedule_Demo/>
      <AppSection/>
      <Footer/>
    </div>
  )
}
export default ScheduleDemo
